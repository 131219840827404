<template>
  <v-main>
    <v-container fluid fill-height>
      <slot/>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style lang="scss" scoped>

</style>
