<template>
  <AuthLayout>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-form @submit.prevent="sendForm">
          <v-card
            class="px-5"
            elevation="2"
            outlined>
            <v-card-text>
              <v-card-title>
                <v-avatar class="mt-5 mr-5">
                  <img
                    src="@/assets/img/sf.png"
                    alt="John"
                  >
                </v-avatar>
                <span class="mt-5">Graffiti Detection PoC</span>
              </v-card-title>
              <v-text-field
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="text"
                v-model="user.email"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="user.password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="mx-5 mb-5">
              <v-btn depressed
                     type="submit">
                Sing in
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </AuthLayout>
</template>

<script>
import { mapActions } from 'vuex';
import AuthLayout from '../../components/Layouts/AuthLayout.vue';

export default {
  name: 'LoginPage',
  components: { AuthLayout },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
    };
  },

  methods: {
    ...mapActions('auth', ['loginUser']),
    sendForm() {
      this.loginUser(this.user);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
